import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import '../../CSS/Home/Navbar.css'; // You can create a separate CSS file for styling

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', closeDropdown);

    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="navbar">
      <div className="nav-logo">
      <Link to="/">
          <img src="../Images/logo1.png" alt="THE LOGICAL MONKS" id="logo" />
        </Link>
      </div>
      <div className={`nav-menu ${isOpen ? 'active' : ''}`}>
        <ul>
          <li><Link to="/">HOME</Link></li>
          <li><Link to="/about">ABOUT US</Link></li>
          <li className={`services ${isDropdownOpen ? 'active' : ''}`} onClick={toggleDropdown} ref={dropdownRef}>
            {/* <Link to="/services">SERVICES</Link> */}
            SERVICES
            <ul className="dropdown">
              <li><Link to="/services/e-commerce">E-Commerce</Link></li>
              <li><Link to="/services/web-development">Web Development</Link></li>
              <li><Link to="/services/mobile-application">Mobile Application</Link></li>
              <li><Link to="/services/digital-marketing">Digital Marketing</Link></li>
            </ul>
          </li>
          <li><Link to="/product">PRODUCT</Link></li>
          <li><Link to="/contact">CONTACT US</Link></li>
        </ul>
      </div>
      <div className="hamburger-menu" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} />
      </div>
    </nav>
  );
};

export default Navbar;


